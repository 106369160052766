@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.layout {
  background-color: #b7e1f9;
  display: flex;
  height: 100vh;
  width: 100vw;
}

.side-bar {
  width: 590px;
  background-color: white;
}

.main-wrapper {
  background-color: white;
  flex: 1;
  background-image: url("../../../public/images/page-bg.png");
  background-size: cover;
  background-position: center;
}

.check-mail-wrapper {
  padding: 50px;
  padding-bottom: 0px;
  font-family: Poppins, "sans-serif";
}

h1 {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 900;
  font-size: 35px;
}

.auth-p {
  color: #808080;
  margin-bottom: 50px;
  font-size: 15px;
}

.back-arrow-wrapper {
  font-size: Poppins, "sans-serif";
  display: flex;
  align-items: center;
  gap: 13px;
  // background-color: gray;
  margin-bottom: 70px;
  // font-size: small;
  width: 80px;
  padding: 10px;
}

.back-arrow-wrapper:hover {
  background-color: #b7e1f9;
  width: 100px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.back-arrow {
  font-size: 20px;
}

.back-text {
  // font-size: 14px;
  font-weight: 700;
  font-size: 18px;
}

a {
  text-decoration: none;
  color: #1491d8;
}

.footer-check-mail {
  font-weight: 400;
  font-family: Poppins, "sans-serif";
  position: relative;
  bottom: -647px;
  text-align: center;
  font-size: 16px;
}
