.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Arial", sans-serif;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 1200px;
}
.weekDay {
  border: 0.5px solid black;
  width: 100%;
  // padding: 30px 30px;
}
.year {
  margin-left: 10px;
}
.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20%;
  margin-bottom: 20px;
  background-color: #ecedf9;
  border-radius: 10px;

  h6 {
    font-size: 15px;
    font-weight: lighter;
    color: #333;
    text-align: center;
  }

  .month-nav {
    background-color: transparent;
    border: none;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
    font-weight: bolder;
    &:hover {
      color: #007bff;
    }
  }
}

.weekdays-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0;
  width: 100%;
}

.day {
  background-color: #ffffff;
  border-right: 0.5px solid #000;
  border-bottom: 0.5px solid #000;
  border-top: 0.5px solid #000;
  border-left: 0.5px solid #000;
  text-align: center;
  padding: 20px 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(1.03);
  }

  .day-number {
    font-size: 14px;
    font-weight: lighter;
    color: #333;
    margin-bottom: 5px;
  }
  .day-number-picked {
    font-size: 14px;
    font-weight: lighter;
    color: #fff;
    margin-bottom: 5px;
  }

  .day-text-picked {
    font-size: 14px;
    color: #1491d8;
    background-color: #fff;
    padding: 0 12px;
    font-size: 12px;
    border-radius: 3px;
  }
  .day-text {
    font-size: 14px;
    color: #777;
    background-color: #1491d8;
    color: #fff;
    padding: 0 12px;
    font-size: 12px;
    border-radius: 3px;
  }

  &.disabled {
    background-color: #f0f0f0;
    color: #bbb;

    .day-number {
      color: #bbb;
    }

    .day-text {
      color: #ccc;
    }
  }

  &.today {
    background-color: #1491d8;
    border: 1px solid #000;
  }
  // &.notToday {
  //   background-color: #000000;
  //   border: 1px solid #000000;
  // }
}
.bottomContainer {
  display: flex;
  justify-content: space-between;
  padding: 60px 50px;
}
.leftContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.rightContainer {
  justify-self: flex-start;
  width: 60%;
}
.switchCon {
  display: flex;
}
.unavailable {
  margin-left: 20px;
  font-size: 15px;
  font-weight: lighter;
}
.input {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 40px;
  align-items: center;
}
.inputLeft {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.selectInput {
  width: 180px;
  height: 37px;
  margin-top: 10px;
  border-radius: 5px;
  padding-left: 7px;
  color: #00000040;
  border-color: #00000060;
}

label {
  font-size: 16px;
  font-weight: lighter;
}
.line {
  border-bottom: 1px solid #00000040;
  width: 60%;
  margin: 20px 0;
}
.buttons {
  margin-top: 25px;
  display: flex;
  width: 30%;
  justify-content: space-between;
}
.discardBtn {
  background-color: transparent;
  color: #ff8577;
  font-size: 13px;
  font-weight: lighter;
  border: 1px solid #ff8577;
  border-radius: 7px;
  height: 40px;
  width: 40%;

  &:hover {
    background-color: transparent;
    color: white;
  }
}

.saveBtn {
  font-size: 13px;
  font-weight: lighter;
  border-radius: 7px;
  background-color: #1491d8;
  border: 1px solid #1491d8;
  overflow: hidden;
  height: 40px;
  width: 40%;
  color: #ffffff;
  &:hover {
    background-color: transparent;
    // color: white;
    background-color: red;
    color: white;
  }
}

.session {
  font-size: 18px;
  font-weight: lighter;
  color: #00000040;
}
.fullDate {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin: 12px 0;
}
.mapped {
  color: #b3b9c4;
  font-size: 12px;
  margin-top: 5px;
}
.mappedSession {
  color: #b3b9c4;
  font-size: 16px;
  margin-top: 5px;
}
.button-availability {
  color: #ffffff;
  background-color: #1491d8;
  border-radius: 8px;
  border: 1px solid #1491d8;
  height: 40px;
  padding: 0 8px;
}
.resetBtn {
  font-size: 13px;
  font-weight: lighter;
  color: #ffffff;
  background-color: #ff8577;
  border-radius: 8px;
  border: 1px solid #ff8577;
  padding: 0 8px;
  height: 40px;
  margin-right: 12px;
  &:hover {
    background-color: #ff8577;
    // color: white;
  }
}
.outterBtnContainer {
  width: 60%;
  margin: 20px 0;
  padding: 10px 0;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}
.iconSessions {
  display: flex;
  align-items: center;
}
.icon {
  color: #007bff;
  margin-left: 20px;
}
.deleteIcon {
  color: #ff8577;
}
.sideIcon {
  color: #000;
}
