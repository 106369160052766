.go-back {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  height: 20px;
  padding: auto;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
}
.back {
  font-size: 14px;
  font-weight: 600;
  margin-top: 60px;
  margin-left: 10px;
}
.s-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 50px;
}
.s-withdraw {
  display: flex;
  justify-self: center;
  align-self: center;
  background-color: #169fed;
  color: #fff;
  width: 35%;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  border: 1px solid blueviolet;
  border-bottom: 3px solid blueviolet;
  border-radius: 16px;
  cursor: pointer;
  margin-top: 100px;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.left-text {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #b3b3b3;
}
.right-text {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.withdraw-content-container {
  width: 35%;
}

@media (max-width: 768px) {
  .s-withdraw {
    display: flex;
    justify-self: center;
    align-self: center;
    background-color: #169fed;
    color: #fff;
    width: 80%;
    justify-content: center;
    align-items: center;
    padding: 14px 0;
    border: 1px solid blueviolet;
    border-bottom: 3px solid blueviolet;
    border-radius: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  .s-withdraw-container {
    background-color: #fff;
    width: 87%;
    height: 80px;
    position: fixed;
    bottom: 1px;
    border-top: 1px solid #b3b3b338;
  }
  .withdraw-content-container {
    width: 100%;
  }
  .left-text {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
    color: #b3b3b3;
  }
  .right-text {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
}
