.nav-container {
  background-color: #fff;
  border-radius: 16px;
  padding: 22px 40px;
  display: flex;

  .nav-item {
    width: fit-content;
    padding: 8px 12px;
    background-color: inherit;
    color: #808080;
    font-weight: 500;
    cursor: pointer;
    &[data-active="active"] {
      color: black;
      border-bottom: 2px solid #34495e;
    }
  }
}
