.walletContainer {
  background-color: white;
  padding-bottom: 20px;
  width: 64%;
  padding: 40px 50px;
  border-radius: 10px;
}
.walletContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.walletContentContainer2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
}
.wallet-title {
  margin-bottom: 0;
  font-weight: 700;
  color: #000;
}
.wallet-title2 {
  margin-bottom: 0;
  font-weight: 700;
  color: #000;
  margin-left: 10px;
}
.createBtn {
  border: none;
}
.makeDefault {
  margin-top: 20px;
  margin-left: 70px;
  align-items: center;
  display: flex;
}
.defaultText {
  font-size: 13px;
  font-weight: 500;
  margin-right: 20px;
}
.payLabel {
  margin-top: 50px;
  margin-bottom: 0;
}
.payoneerContainer {
  width: 100%;
  border: 1px solid #b3b3b3;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px;
  border-radius: 15px;
  align-items: center;
}
.payoText {
  font-size: 13px;
  font-weight: 700;
}
.payoneerContainer2 {
  width: 100%;
  border: 1px solid #b3b3b3;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px;
  border-radius: 15px;
  align-items: center;
}
.contentContainer3 {
  margin-left: 70px;
}
.link {
  color: #1490d8a0;
}
.payoneer {
  margin-bottom: 0;
  padding: 8px 0;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
}
.account {
  margin-bottom: 0;
  padding: 8px 0;
  font-weight: 600;
  font-size: 16px;
}
.default {
  margin-bottom: 0;
  padding: 5px 12px;
  border: 1px solid #1490d8a0;
  width: 73px;
  border-radius: 14px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}
.edit {
  margin-bottom: 15px;
  color: #1490d8a0;
  border: 1px solid #1490d8a0;
  padding: 2px 10px;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.delete {
  margin-bottom: 0;
  color: #ff5252;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}
.setUp {
  display: flex;
  justify-self: flex-end;
  align-self: flex-end;
  background-color: #fff;
  color: #fff;
  width: 25%;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  border: 1px solid #1490d8a0;
  border-bottom: 3px solid rgb(60, 4, 112);
  color: #1490d8a0;
  border-radius: 16px;
  cursor: pointer;
  margin-top: 20px;
}
.doneButton {
  display: flex;
  justify-self: center;
  align-self: center;
  background-color: #1490d8a0;

  width: 25%;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  border: 1px solid #1490d8a0;
  border-bottom: 3px solid rgb(60, 4, 112);
  color: #fff;
  border-radius: 16px;
  cursor: pointer;
  margin-top: 50px;
}
.payInput {
  width: 40%;
  border: transparent;
  border-bottom: 1px solid black;
  border-radius: 0;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 20px;
  font-size: 17px;
  margin-top: 20px;
}
.payInput:focus {
  border-bottom: 1px solid black;
  outline: none;
}
.createBtnAdd {
  padding: 5px 10px;
  background-color: #169fed;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  border: none;
}

@media (max-width: 768px) {
  .walletContainer {
    background-color: white;
    height: 400px;
    width: 100%;
    padding: 10px 10px;
    border-radius: 10px;
    margin-top: 20px;
  }
  .walletContentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 80px;
  }
  .payoneerContainer {
    width: 100%;
    border: 1px solid #b3b3b3;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 15px;
    border-radius: 15px;
    align-items: initial;
  }
  .createBtnAdd {
    padding: 5px 10px;
    background-color: #169fed;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    height: 30px;
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
    position: absolute;
    margin-top: 90px;
    width: 70%;
    border: none;
  }
  .editDelete {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .delete {
    margin-bottom: 0;
    color: #ff5252;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    margin-left: 30px;
    margin-bottom: 15px;
  }
  .setUp {
    display: flex;
    justify-self: flex-end;
    align-self: flex-end;
    background-color: #fff;
    color: #fff;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 14px 0;
    border: 1px solid #1490d8a0;
    border-bottom: 3px solid rgb(60, 4, 112);
    color: #1490d8a0;
    border-radius: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  .contentContainer3 {
    margin-left: 0;
  }
  .payInput {
    width: 80%;
    border: transparent;
    border-bottom: 1px solid black;
    border-radius: 0;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 20px;
    font-size: 17px;
    margin-top: 20px;
  }
  .makeDefault {
    margin-top: 20px;
    margin-left: 10px;
    align-items: center;
    display: flex;
  }
}
