@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.layout {
  background-color: #b7e1f9;
  display: flex;
  height: 100vh;
  width: 100vw;
}

.login-wrapper {
  padding: 50px;
  padding-bottom: 0px;
  font-family: Poppins, "sans-serif";
}

.side-bar {
  width: 590px;
  background-color: white;
}

.main-wrapper {
  background-color: white;
  flex: 1;
  background-image: url("../../../public/images/page-bg.png");
  background-size: cover;
  background-position: center;
}

.inner-container {
  padding: 20px;
  background-color: #337357;
  width: 400px;
  border-radius: 8px;
  margin: auto;
  height: 540px;
  text-align: center;
  color: #ccc;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 -8px 16px rgba(0, 0, 0, 0.3),
    8px 0 16px rgba(0, 0, 0, 0.3), -8px 0 16px rgba(0, 0, 0, 0.3);
}

.inner-container p {
  margin: 15px;
}

.logo-wrapper {
  text-align: center;
}

.label-style {
  margin-top: 9px;
  margin-right: 8px;
}

label {
  font-size: 22px;
}

.password-input {
  border-radius: 0px;
  // padding: 40px;
  height: 70px;
  font-weight: 400;
  border: none;
  // background-color: goldenrod;
  border-bottom: 2px solid #2d2d2d;
  font-family: Poppins, "sans-serif";
  font-size: 20px;
  outline: none;
}

.password-input::placeholder {
  font-size: 20px;
  padding-bottom: 50px;
}

.button {
  width: 250px;
  // height: 60px;
  background-color: #169fed;
  border-radius: 12px;
  margin: 0 auto;
  border: none;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
  transition: all 0.3s ease;
  font-family: Poppins, "sans-serif";
  font-size: 20px !important;
  letter-spacing: 1px;
  color: white;
}

.button:hover {
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.35);
  transform: translateY(-4px);
}

.sign-up {
  background-color: #ccc;
  padding: 8px 75px;
  border-radius: 25px;
  margin: 25px 0px;
  color: #337357;
  font-weight: 1000;
  transition: transform 0.3s ease;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-family: Nunito, sans-serif;
  font-size: 16px;
}

.sign-up:hover {
  background-color: #e0e0e0;
  transform: scale(1.05);
}

.already-have {
  font-family: Poppins, "sans-serif";
  font-size: 18px;
  cursor: pointer;
  margin-top: 30px;
  text-align: center;
}

.register,
.recover {
  color: #169fed;
  cursor: pointer;
  font-family: Poppins, "sans-serif";
  font-size: 20px !important;
}

.register-text {
  margin-top: 5px;
  text-align: center;
}

.error-message {
  color: red;
  margin-bottom: 10px;
  text-align: center;
  font-family: Poppins, "sans-serif";
  text-align: center;
  font-family: Poppins, "sans-serif";
  font-size: 20px;
}

.show-toggle {
  // background-color: goldenrod;
  position: relative;
  top: -111px;
  right: -450px;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #1491d8;
}

.footer-login {
  font-weight: 400;
  font-family: Poppins, "sans-serif";
  position: relative;
  bottom: -138px;
  text-align: center;
  font-size: 16px;
}
