.dashboard {
  display: flex;
  height: 100vh;
  width: 100vw;
  // background-color: red;

  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;

    &:focus {
      outline: none;
    }
  }
  .close {
    width: 100%;
  }

  .sidebar {
    width: 250px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    transition: transform 0.3s ease-in-out;

    &.open {
      transform: translateX(0);
    }

    .sidebar-header {
      color: #1491d8;
      text-align: center;
      font-size: 24px;
      background-color: #fff;
      padding: 20px;
    }

    .sidebar-nav {
      flex-grow: 1;

      ul {
        list-style: none;
        padding: 0;

        li {
          a {
            color: #000000;
            text-decoration: none;
            display: block;
            padding: 15px 20px;
            font-size: 16px;
            cursor: pointer;

            &:hover {
              color: #1491d8;
            }

            &.active-link {
              color: #1491d8;
              font-weight: bold;
            }
          }

          &:hover {
            background: #f5f5f5;
          }
        }
      }
    }
  }
}

.main-content {
  flex-grow: 1;
  background-color: #ecf0f1;
  padding: 20px;
  overflow-y: auto;

  .dashboard-header {
    background-color: #34495e;
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    font-size: 22px;
  }

  .dashboard-content {
    margin-top: 20px;
    padding: 30px;
    border-radius: 10px;
    font-size: 18px;
    line-height: 1.6;
  }
}

@media (max-width: 768px) {
  .main-content {
    flex-grow: 1;
    background-color: #fff;
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
    z-index: 1000;

    .dashboard-header {
      background-color: #34495e;
      color: white;
      padding: 0;
      text-align: center;
      border-radius: 5px;
      font-size: 22px;
    }

    .dashboard-content {
      margin-top: 20px;
      padding: 30px;
      border-radius: 10px;
      font-size: 18px;
      line-height: 1.6;
    }

    &.open {
      transform: translateX(0);
    }
  }
}

@media (min-width: 769px) {
  .ham {
    display: none;
  }
  .close {
    display: none;
  }

  .sidebar {
    transform: none;
    position: relative;
  }
}
