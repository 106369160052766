.profilePictureContainer {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;
  height: 300px;
}
.basicInfoContainer {
  background-color: white;
  padding: 20px 30px;
  margin-top: 10px;
  border-radius: 10px;
}
.workExperienceContainer {
  background-color: white;
  padding: 5px 30px;
  margin-top: 10px;
  border-radius: 10px;
}
.aboutMeContainer {
  background-color: white;
  padding: 20px 30px;
  margin-top: 10px;
  border-radius: 10px;
}
.certificationContainer {
  background-color: white;
  padding: 20px 30px;
  margin-top: 10px;
  border-radius: 10px;
}
.placeholderProfilePicture {
  height: 150px;
  width: 150px;
  background-color: #b4b6b7df;
  border-radius: 50%;
  object-fit: contain;
  margin-right: 30px;
}
.profilePictureHeader {
  display: flex;
  align-items: center;
  height: 50px;
}
.profilePictureText {
  font-size: 16px;
  font-weight: 700;
  margin: 30px 0;
  height: 70px;
}
.titleText {
  font-size: 17px;
  font-weight: lighter;
  color: #808080;
  height: 100px;
}
.camera {
  font-size: 17px;
  font-weight: lighter;
  color: #808080;
  border: 1px solid #808080;
  width: 180px;
  padding-left: 15px;
  padding: 5px 15px;
  border-radius: 10px;
  margin: 20px 0;
  height: 35px;
}
.cameraIcon {
  padding-right: 16px;
}
.sizeFormat {
  font-size: 12px;
  font-weight: lighter;
  margin-top: 20px;
}
.labelProfile {
  font-size: 14px;
  font-weight: lighter;
  color: #808080;
}
.profileInput {
  background-color: transparent;
  width: 70%;
  height: 45px;
  border: 1px solid #ecedf9;
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  &::placeholder {
    color: rgb(182, 180, 180);
    font-size: 12px;
  }
}
.profileTextarea {
  max-width: 70%;
  min-width: 70%;
  max-height: 80px;
  min-height: 80px;
  border: 1px solid #ecedf9;
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  padding-top: 3px;
  &::placeholder {
    color: rgb(182, 180, 180);
    font-size: 12px;
  }
}
.profileMainContainer {
  display: flex;
  width:100%;
  justify-content: space-between;
  gap: 10px;
}
.profileLeftContainer {
  width: 32%;
  height: 500px;
  background-color: white;
  border-radius: 10px;
}
.profileRightContainer {
  width: 64%;
  background-color: transparent;
}
.rightHeader {
  border-bottom: 1px solid #1490d8a0;
  background-color: #1490d8a0;
  padding-top: 10px;
  padding-left: 25px;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  height: 60px;
}
.rightHeaderU {
  border-bottom: 1px solid #1490d8a0;
  background-color: #fff;
  padding-top: 10px;
  padding-left: 25px;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
  height: 60px;
}
.rightHeaderP {
  border-bottom: 1px solid #1490d8a0;
  background-color: #1490d8a0;
  padding-top: 10px;
  padding-left: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 60px;
}
.rightHeaderPU {
  border-bottom: 1px solid #1490d8a0;
  background-color: white;
  padding-top: 10px;
  padding-left: 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 60px;
}
.topTitle {
  font-size: 16px;
  font-weight: 500;
  color: #808080;
  display: none;
}
.aboutBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
}
.aboutSaveBtn {
  background-color: #1491d8;
  color: white;
  font-size: 16px;
  font-weight: lighter;
  width: 47%;
  border: none;
  padding: 10px 0;
  &:hover {
    background-color: #00000070;
  }
}
.aboutCancelBtn {
  background-color: #00000070;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  width: 47%;
  border: none;
  padding: 10px 0;
  &:hover {
    background-color: #00000070;
  }
}
.errorMessage {
  color: #e64444ca;
  font-size: 13px;
  margin-bottom: 20px 0;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 20%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}
.topSubTitle {
  font-size: 16px;
  font-weight: 700;
  color: #808080;
  display: inline;
  color: #000;
  margin-bottom: 0;
}
.topSubTitleTop {
  font-size: 16px;
  font-weight: 700;
  color: #808080;
  display: none;
  color: #000;
  margin-bottom: 0;
}
.sectionTitle {
  font-size: 16px;
  font-weight: 700;
  margin: 20px 0;
}

/* Keyframes for loader spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .placeholderProfilePicture {
    height: 60px;
    width: 60px;
    background-color: #b4b6b7df;
    border-radius: 50%;
    object-fit: contain;
    margin-right: 15px;
  }
  .profilePictureHeader {
    display: flex;
    align-items: flex-start;
    height: 80px;
    width: 150%;
  }
  .rightHeader {
    border-bottom: 2px solid #000;

    background-color: transparent;
    padding: 0 30px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 30px;
  }
  .rightHeaderU {
    border-bottom: 1px solid #1490d8a0;
    

    background-color: transparent;
    padding: 0 30px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 30px;
  }
  .rightHeaderP {
    border-bottom: 2px solid #000;
    background-color: transparent;
    padding: 0 30px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 30px;
  }
  .rightHeaderPU {
    border-bottom: 1px solid #1490d8a0;
    background-color: transparent;
    padding: 0 30px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 30px;
  }
  .profileRightContainer {
    width: 64%;
    margin-top: 60px;
  }
  .profilePictureContainer {
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 10px;
    height: 100px;
  }
  .basicInfoContainer {
    background-color: white;
    padding: 5px 8px;
    margin-top: 4px;
    border-radius: 10px;
    width: 300px;
  }
  workExperienceContainer {
    background-color: white;
    padding: 5px 8px;
    margin-top: 4px;
    border-radius: 10px;
  }
  .aboutMeContainer {
    background-color: white;
    padding: 4px 8px;
    margin-top: 4px;
    border-radius: 10px;
    width: 300px;
  }
  .certificationContainer {
    background-color: white;
    padding: 4px 8px;
    margin-top: 4px;
    border-radius: 10px;
    width: 300px;
  }
  .titleText {
    font-size: 8px;
    font-weight: lighter;
    color: #808080;
    width: 200px;
  }
  .camera {
    font-size: 8px;
    font-weight: lighter;
    color: #808080;
    border: 1px solid #808080;
    width: 90px;
    height: 20px;
    padding-left: 6px;
    padding: 3px 5px;
    border-radius: 10px;
    margin: 10px 0;
  }
  .profilePictureText {
    display: none;
  }
  .sectionTitle {
    font-size: 16px;
    font-weight: 700;
    margin: 5px 0;
  }
  .cameraIcon {
    padding-right: 8px;
  }
  .sizeFormat {
    font-weight: lighter;
    font-size: 9px;
    width: 100%;
  }
  .labelProfile {
    font-size: 12px;
    font-weight: lighter;
    color: #808080;
  }
  .profileInput {
    width: 100%;
    height: 45px;
    border: 1px solid #ecedf9;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
    &::placeholder {
      color: rgb(182, 180, 180);
      font-size: 12px;
    }
  }
  .profileTextarea {
    max-width: 100%;
    min-width: 100%;
    max-height: 100px;
    min-height: 100px;
    border: 1px solid #ecedf9;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
    padding-top: 3px;
    &::placeholder {
      color: rgb(182, 180, 180);
      font-size: 12px;
    }
  }
  .profileMainContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .profileLeftContainer {
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 10px;
    display: flex;
    overflow-x: scroll;
    padding: 10px;
    
  }

  .aboutBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 48%;
  }
  .topTitle {
    font-size: 14px;
    font-weight: 400;
    display: inline;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .topSubTitle {
    font-size: 12px;
    font-weight: 700;
    color: #808080;
    display: inline;
    margin-bottom: 0;
  }
  .aboutSaveBtn {
    background-color: #1491d8;
    color: white;
    font-size: 8px;
    font-weight: 500;
    width: 47%;
  }
  .aboutCancelBtn {
    background-color: #00000070;
    color: #000;
    font-size: 8px;
    font-weight: 500;
    width: 47%;
    &:hover {
      background-color: #00000070;
    }
  }
  .errorMessage {
    color: #e64444ca;
    font-size: 6px;
    margin-bottom: 5px 0;
  }

  .loader {
    border: 3px solid #f3f3f3; /* Light grey */
    border-top: 3px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
  }
}

