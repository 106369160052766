.schedule-lesson {
  background: white;
  border-radius: 12px;
  max-width: 660px;
  padding: 54px 40px;

  .first-row {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;

    .date {
      font-weight: 700;
      color: black;
      margin: 0;
      margin-bottom: 12px;
    }

    .time {
      color: #808080;
      font-weight: 500;
      font-size: 20px;
    }

    .language {
      font-size: 16px;
      font-weight: 500;
      color: #1491d8;
    }
  }

  .student {
    margin-bottom: 48px;

    .title {
      margin-bottom: 4px;
      color: #808080;
      font-size: 12px;
    }

    .description {
      color: black;
      font-size: 14px;
    }
  }

  .lesson {
    display: block;
    margin-bottom: 48px;

    .lesson--title {
      font-weight: 600;
      color: black;
      font-size: 14px;
    }

    .title {
      margin-bottom: 4px;
      color: #808080;
      font-size: 12px;
    }

    .description {
      color: #808080;
      font-size: 14px;
    }
  }

  .classroom {
    margin-bottom: 64px;
    display: flex;
    gap: 24px;
    justify-content: space-between;

    .column {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex-basis: 33%;

      .bottom {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
      }
    }
  }

  button.action {
    max-width: 160px;
  }
}

.loading {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .schedule-lesson {
    background: white;
    border-radius: 12px;
    max-width: 660px;
    padding: 24px 10px;

    .first-row {
      margin-bottom: 48px;
      display: flex;
      justify-content: space-between;

      .date {
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 12px;
        font-size: 12px;
      }

      .time {
        color: #808080;
        font-weight: 500;
        font-size: 12px;
      }

      .language {
        font-size: 16px;
        font-weight: 500;
        color: #1491d8;
      }
    }

    .student {
      margin-bottom: 20px;
      justify-content: flex-start;
      align-items: flex-start;

      .title {
        margin-bottom: 4px;
        align-self: flex-start;
        color: #808080;
        font-size: 12px;
        text-align: left;
      }

      .description {
        color: black;
        font-size: 14px;
      }
    }

    .lesson {
      display: block;
      margin-bottom: 48px;

      .lesson--title {
        font-weight: 600;
        color: black;
        font-size: 14px;
      }

      .title {
        text-align: left;
        margin-bottom: 4px;
        color: #808080;
        font-size: 12px;
      }

      .description {
        color: #808080;
        font-size: 14px;
      }
    }
  }
  .classroom {
    margin-bottom: initial;
    display: initial;
    flex-direction: column;
    gap: initial;
    justify-content: initial;
    align-items: flex-start;

    .column {
      display: flex;
      flex-direction: row;
      gap: initial;
      flex-basis: initial;

      .bottom {
        display: initial;
        align-items: initial;
        gap: initial;
        font-size: initial;
      }
    }
  }
}
