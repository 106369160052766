.languagesContainer {
  flex-direction: row;
  display: flex;
  height: 40px;
}
.language {
  padding: 5px 10px;
  border-radius: 12px;
  margin-right: 10px;
  font-size: 16px;
}
.languagePicked {
  border-bottom: 2px solid black;
  padding: 5px 10px;
  margin-right: 10px;
  font-size: 16px;
}
.lessonsContainer {
  margin-top: 5px;
  border-radius: 17px;
}
.lessonsEach {
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 15px;
  margin-bottom: 10px;
}
.lesson {
  border-width: 10px;
  border-color: red;
  display: flex;
  margin: 20px 20px 0 0;
  justify-content: space-between;
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  padding: 10px 20px;
}
.createBtn {
  padding: 5px 10px;
  background-color: #169fed;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #ffffff;
  font-weight: 500;
  font-size: 10px;
}
.add {
  color: #ffffff;
  font-size: 14px;
  font-weight: lighter;
}
.title {
  font-size: 14px;
  font-weight: lighter;
  color: rgb(182, 180, 180);
}
.summary {
  color: #169fed;
  font-size: 15px;
  font-weight: lighter;
  margin-bottom: 10px;
}
.subTitle {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
}
.eachSession {
  // display: flex;
  // flex-direction: column;
  // width: 20%;
  // justify-content: flex-start;
}
.leftPackageContainer {
  display: flex;
  justify-content: space-between;
  flex-grow: 6;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.package {
  align-items: center;
  display: flex;
  width: 100%;

  flex-direction: row;
  margin-top: 16px;
}
.deleteIcon {
  margin-top: 30px;
  flex-grow: 1;
  cursor: pointer;
}
.subTitleText {
  margin-bottom: 20px;
  margin-top: 5px;
}
.inputSubtitle {
  margin-bottom: 20px;
  margin: 10px 0;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 0.5px;
  color: rgb(182, 180, 180);
}
.form {
  display: flex;
  justify-content: space-between;
  margin: 10px 30px;
}
.sideA {
  width: 40%;
}
.sideB {
  width: 50%;
}
.lessonTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}
.label {
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  margin-top: 7px;
}
.textarea {
  max-height: 200px;
  max-width: 80%;
  min-width: 80%;
  min-height: 100px;

  &::placeholder {
    color: rgb(182, 180, 180);
  }
}
input {
  color: black;
  border-radius: 12px;
  font-size: 12px;
  font-weight: lighter;
  &::placeholder {
    color: rgb(182, 180, 180);
    font-size: 12px;
  }
}
.subTitle {
  color: black;
  border-radius: 10px;
  font-size: 12px;
  font-weight: lighter;
}
.sideBContainer {
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-top: 20px;
}
.packageInput {
  width: 90%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 9px;
  font-size: 16px;
}

.removePackageButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 25px;
  margin-right: 20px;
}
.removePackageButton {
  width: 100px;
  background-color: #169fed;
  font-size: 12px;
  color: #fff;
}
.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 25px;
}
.addPackageButtonStyle {
  width: 120px;
  background-color: transparent;
  font-size: 11px;
  color: #ff8577;
  border: 1px solid #ff8577;
  font-size: 13px;
  font-weight: lighter;
  border-radius: 7px;
  padding: 12px 17px;
  &:hover {
    background-color: transparent;
  }
}
.buttonStyle {
  width: 120px;
  background-color: #169fed;
  font-size: 11px;
  color: #fff;
  font-size: 13px;
  font-weight: lighter;
  border-radius: 7px;
  padding: 13px 17px;
  margin-left: 12px;
  &:hover {
    background-color: #169fed;

    // color: white;
  }
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Backdrop color with transparency */
  z-index: 999;
}

.lessonHeaderTitle {
  display: none;
}
.subTitleHeader {
  display: none;
}

@media (max-width: 768px) {
  .languagesContainer {
    flex-direction: row;
    display: flex;
    height: 30px;
  }
  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px 5px;
  }
  .createBtn {
    padding: 5px 10px;
    background-color: #169fed;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    height: 30px;
    color: #ffffff;
    font-weight: 500;
    font-size: 10px;
    position: absolute;
    top: 20px;
    right: 30px;
  }
  .language {
    padding: 3px 5px;
    border-radius: 12px;
    margin-right: 4px;
    font-size: 10px;
  }
  .languagePicked {
    border-bottom: 2px solid black;
    padding: 3px 5px;
    margin-right: 4px;
    font-size: 10px;
  }
  .lessonHeaderTitle {
    display: inline;
  }
  .subTitleHeader {
    display: inline;
  }
}
