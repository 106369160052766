.schedule-card--container {
  background: #fff;
  max-width: 660px;
  width: 100%;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  &:hover {
    cursor: pointer;
  }

  &[data-status="in progress"] {
    border: 2px solid #ff5252;
  }

  &[data-status="action required"] {
    border: 2px solid #f7cb1599;
  }

  .row {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .date {
        font-weight: 600;
        margin: 0;
      }

      .time {
        color: #808080;
        font-weight: 500;
        font-size: 20px;
      }
    }
  }

  .language {
    font-size: 16px;
    font-weight: 500;
    color: #1491d8;
  }

  .status-container {
    background-color: #ecedf9;
    border-radius: 9999px;
    padding: 4px 8px;
    display: flex;
    gap: 10px;
    align-items: center;
    width: fit-content;

    &[data-status="upcoming"] {
      display: none;
    }

    .status-dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border-width: 2px;
      border-style: solid;
      &[data-status="completed"] {
        background-color: #1491d8;
        border-color: #1491d8;
      }

      &[data-status="action required"] {
        background-color: #f7cb15;
        border-color: #f7cb1599;
      }

      &[data-status="in progress"] {
        background-color: #ff5252;
        border-color: #ff8577b2;
      }
    }

    .status-text {
      font-size: 12px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
}
