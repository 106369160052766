@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.layout {
  background-color: #b7e1f9;
  display: flex;
  height: 100vh;
  width: 100vw;
}

.side-bar {
  width: 590px;
  background-color: white;
}

.main-wrapper {
  background-color: white;
  flex: 1;
  background-image: url("../../../public/images/page-bg.png");
  background-size: contain;
  background-position: center;
}

.forgot-password-wrapper {
  padding: 50px;
  padding-bottom: 0px;
  font-family: Poppins, "sans-serif";
  // height: 100%;
}

h1 {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 900;
  font-size: 35px;
}

p {
  color: #808080;
  margin-bottom: 50px;
  font-size: 16px;
}

.display-footer {
  background-color: green;
}

.back-arrow-wrapper {
  font-size: Poppins, "sans-serif";
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 50px;
}

.back-arrow {
  font-size: 20px;
}

.back-text {
  // font-size: 14px;
  font-weight: 700;
  font-size: 18px;
}

.form-wrapper {
  color: #808080;
  display: flex;
  flex-direction: column;
  // background-color: yellow;
  gap: 20px;
  margin-bottom: 70px;
  font-family: Poppins, "sans-serif";
}

label {
  font-size: 22px;
}

.password-input::placeholder {
  font-size: 20px;
  padding-bottom: 50px;
}

.password-input {
  border-bottom: 2px solid #2d2d2d;
  border-radius: 0px;
  // padding: 40px;
  height: 70px;
  font-weight: 400;
  // background-color: goldenrod;
  color: black !important;
  font-size: 20px !important;
  font-family: Poppins, "sans-serif";
  margin-bottom: 50px;
}

.button {
  width: 250px;
  height: 60px;
  background-color: #169fed;
  border-radius: 12px;
  margin: 0 auto;
  border: none;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
  transition: all 0.3s ease;
  font-family: Poppins, "sans-serif";
  font-size: 20px !important;
  letter-spacing: 1px;
  cursor: pointer;
  // margin-top: 70px;
}

.button:hover {
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.35);
  transform: translateY(-4px);
}

a {
  text-decoration: none;
  color: #1491d8;
}

.footer-forgot-password {
  font-weight: 400;
  font-family: Poppins, "sans-serif";
  position: relative;
  bottom: -323px;
  text-align: center;
  font-size: 16px;
}

.error {
  color: red;
  font-family: Poppins, "sans-serif";
  font-size: 20px;
  text-align: center;
}
