.go-back {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  height: 20px;
  padding: auto;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
}
.back {
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  margin-bottom: 60px;
}
.w-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 50px;
}
.w-message {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 29px;
  font-weight: 300;
  color: #b3b3b3;
}
.w-balance {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 12px;
  font-weight: 500;
  color: #000;
  margin-top: 40px;
}
.w-amount {
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 17px;
  font-weight: 700;
  color: #000;
  margin-top: 10px;
}
.w-label {
  font-size: 15px;
  font-weight: 300;
}
.w-input {
  width: 100%;
  border: transparent;
  border-bottom: 1px solid black;
  border-radius: 0;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 20px;
  font-size: 17px;
  margin-top: 20px;
}

.w-input:focus {
  border-bottom: 1px solid black;
  outline: none;
}
.w-account {
  padding-top: 40px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  margin-bottom: 20px;
}
.w-user-account {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.w-account-details {
  width: 40%;
  font-weight: 600;
  font-size: 14px;
  color: #000;
}

.w-change-account {
  color: #169fed;
  font-weight: 500;
  font-size: 14px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.w-emergency {
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 14px;
}
.w-48hrs {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
}
.w-emergency-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.w-notice {
  color: #ff5252;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 15px;
}
.w-notice-subtitle {
  font-weight: 400;
  font-size: 15px;
}
.w-proceed {
  display: flex;
  justify-self: center;
  align-self: center;
  background-color: #169fed;
  color: #fff;
  width: 35%;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  border: 1px solid blueviolet;
  border-bottom: 3px solid blueviolet;
  border-radius: 16px;
  cursor: pointer;
  margin-top: 20px;
}
.withdraw-content-container {
  width: 40%;
}
.dropdown {
  background-color: lightgray;
  position: absolute;
  top: 28px;
  padding: 20px 20px;
  border-radius: 15px;
  z-index: 1;
  height: 130px;
  overflow-x: scroll;
}
.payMethodText {
  margin-bottom: 10px;
  color: #fff;
  font-weight: 400;
}

@media (max-width: 768px) {
  .withdraw-content-container {
    width: 100%;
  }
  .w-proceed {
    display: flex;
    justify-self: center;
    align-self: center;
    background-color: #169fed;
    color: #fff;
    width: 80%;
    justify-content: center;
    align-items: center;
    padding: 14px 0;
    border: 1px solid blueviolet;
    border-bottom: 3px solid blueviolet;
    border-radius: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  .w-proceed-container {
    background-color: #fff;
    width: 87%;
    height: 80px;
    position: fixed;
    bottom: 1px;
    border-top: 1px solid #b3b3b338;
  }
  .w-emergency-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  .dropdown {
    background-color: lightgray;
    position: absolute;
    bottom: -150px;
    padding: 20px 20px;
    border-radius: 15px;
    z-index: 1;
  }
  .payMethodText {
    margin-bottom: 8px;
    color: #fff;
    font-weight: 400;
  }
}
