  .dialogModal{
    position: relative;

    .dialogBox{
      position: absolute;
      padding:20px 20px;
      top: 50%;
      left:50%;
      z-index:10000000;
      height:auto;
      width:auto;
      background-color:white;
      transform:translate(-50%, -50%);
        box-shadow: 5px 5px  25px rgba(128, 128, 128, 0.368);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10000000000000;


    }
    .boxed{
        width: 70%;
    }
    .dHead{
        font-weight: bold;
        font-size: 36px;
    }

    .dMesa p{
      text-align: center;
      margin: 10px;
    }

    .btnBox{
        display: flex;
        justify-content: center;
        gap:20%;
        margin: 20px 0px;
    }

    .dBtn{
        padding:5px 5px;
        width: 100%;
    }

    .submits{
        background-color: #3498db;
        outline:none;
        border: none;
        border-radius: 5px;
        color: white;
    }

    .dOverlay{
        position: fixed;
        top: 0;
        right:0;
        bottom: 0;
        left:0;
        background-color: rgba(128, 128, 128, 0.343);
        height: 100%;
        width: 100%;
        z-index: 10000000000000;
    }

  }
