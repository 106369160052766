.dashboard-cards {
  display: flex;
  flex-wrap: wrap; /* Ensure the cards wrap on smaller screens */
  justify-content: space-around;
  gap: 10px;
  flex-direction: row;
}
@media (max-width: 768px) {
  .dashboard-cards {
    flex-direction: column; /* Stack cards vertically on mobile */
    align-items: center;
  }
}

.container {
  background-color: white;
}
