.balance-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .card-content {
    h3 {
      font-size: 1.5rem;
      margin-bottom: 10px;
      color: #333;
    }

    .balance-amount {
      font-size: 2.5rem;
      font-weight: bold;
      color: #3498db; /* Customize the color as needed */
      margin-bottom: 20px;
    }

    .withdraw-button {
      background-color: #e74c3c;
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 1rem;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #c0392b;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
