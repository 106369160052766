button.custom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    opacity: 60%;
  }

  &[data-variant="primary"] {
    background: #000;
    color: #fff;
    padding: 12px 20px;
    border-radius: 12px;
    margin: auto;
    //   max-width: 160px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
  }
}
