.comCont *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.comCont{
    width: 64%;
    display: flex;
    flex-direction: column;
    gap:2rem;



    
  .selected{
    width: 100%;
    padding:1rem  2rem ;
    background-color: white;
    border-radius: .5rem;

    .SelectH1{
        font-weight: bold;
    }

    .listBox{
        margin: 2rem 0px;
        
    }

    .comBox{
        display: flex;
        align-items: center;
        gap: 2rem;
        margin: 10px 0px;
        width: 100%;


        .id{
        display: flex ;
        align-items: center;
        gap:2rem;
        width: 100%;


        p{
            padding: 5px ;
            border: 1px solid rgba(128, 128, 128, 0.454);
            border-radius: .2rem;
            width: 50%;
            // text-wrap:nowrap;
            overflow-x: scroll;
        }
       

    }
     .close{
            cursor: pointer;

        }

    }


  }

}


.communicate{
    width: 100%;
    height: auto;
    border-radius: .5rem;
    position: relative;
    background-color: white;
    

    .header{
        padding: 1rem 2rem;
        font-weight: bolder;
        border-bottom: 1px solid rgba(128, 128, 128, 0.468);
        color: black;
    }

    .textBody {
        padding: 2rem;
    }

    .formm{
        height: 100%;
    }

    .picker{
        display: flex;
        margin: 3rem 0rem;
        gap: 1rem;
        width: 60%;
        
    }

    .selection{
        width: 100%;
        padding: 5px;
        border-radius: 5px;
        font-size: 16px;

    }

    .field{
        border: 1px solid grey;
        padding: 5px;
        border-radius: 5px;
        width: 100%;
        border-radius: 2px;
        font-size: 18px;
    
    }
     input::placeholder{
     font-size: 16px;
  }
  
  button{
    padding: .3rem 2rem;
    background-color: #1491d8;
    border:none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  } 
}

@media (max-width:768px) {
   .comCont{
    width: 100%;
    margin: 2rem 0rem;

    .header{
        border: 0;
        padding: 1rem 0;
    }
    .picker{
        width: 100%;
        padding: 0;
    }

    .textBody{
        padding: 0px;
    }

      .selection{
        width: 100%;
        padding: 5px;
        border-radius: 5px;
        font-size: 14px;
        border: 1px solid rgba(128, 128, 128, 0.408);


    }

    .field{
        border: 1px solid rgba(128, 128, 128, 0.408);
        padding: 5px;
        border-radius: 5px;
        width: 100%;
        border-radius: 2px;
        font-size: 14px;
        outline: none;
    
    }

    .selected{
        padding: 0px 0px;
        .comBox{
            gap: .5rem;
            width: auto;

        .id{
            gap: 1rem;
        p{
            font-size: 11px;
        }

        }
        }

        
    }
}
}

@media (max-width:1024px) {
    .comCont{

    
    

      .selection{
        width: 100%;
        padding: 5px;
        border-radius: 5px;
        font-size: 14px;
        border: 1px solid rgba(128, 128, 128, 0.408);


    }

    .field{
        border: 1px solid rgba(128, 128, 128, 0.408);
        padding: 5px;
        border-radius: 5px;
        width: 100%;
        border-radius: 2px;
        font-size: 14px;
        outline: none;
    
    }

    .selected{
        padding: 10px 10px;
        .comBox{
            gap: .5rem;
            width: auto;

        .id{
            gap: 1rem;
        p{
            font-size: 11px;
        }

        }
        }

        
    }
}
}