.loaderContainer {
  // width: 100%;
  // height: 100%;
  z-index: 10;
  // position: absolute;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
}

.appLoader {
  width: 400px;
  height: 400px;
  object-fit: contain;
}
