.mainContainer {
  width: 30%;
  border-radius: 10px;
  background-color: white;
  height: 700px;
}
.detailContainer {
  width: 65%;
  border-radius: 10px;
  background-color: white;
  height: 700px;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: transparent;
}
.mainTop {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0 0;
  height: 60px;
}
.topText {
  text-align: center;
  font-weight: lighter;
  font-size: 16px;
  width: 50%;
  height: 40px;
}
.topTextPicked {
  border-bottom: 2px solid black;
  text-align: center;
  font-weight: bolder;
  font-size: 16px;
  padding-bottom: 7px;
  width: 50%;
  height: 40px;
}
.mapItemContainer {
  border-bottom: 1px solid black;
  padding: 0 15px;
  margin-top: 10px;
  height: 130px;
}
.image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #b4b6b7df;
}

.inner {
  display: flex;
  flex-direction: row;
  height: 60px;
}
.messageContent {
  height: 40px;
}
.mappedContent {
  overflow: scroll;
  height: 640px;
}
.name {
  font-size: 14px;
  font-weight: bold;
}
.content {
  font-size: 14px;
  font-weight: lighter;
  color: #808080;
}
.date {
  text-align: right;
  font-size: 14px;
  font-weight: lighter;
}
.dateNote {
  text-align: center;
  font-size: 14px;
  font-weight: lighter;
  margin: 10px;
  color: #808080;
}
.header {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 25px;
  height: 40px;
}
.notContent {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  height: 640px;
  overflow: scroll;
  padding: 0 130px;
}
.imageNot {
  width: 600px;
  height: 200px;
  object-fit: cover;
  border: 5px solid #e1e8ed;
  border-radius: 15px;
  margin-top: 10px;
}
.fullContent {
  width: 600px;
  margin-top: 40px;
  font-size: 14px;
  font-weight: lighter;
  word-spacing: 1px;
  letter-spacing: 1px;
}
.messagesContainer {
  height: 565px;
}
.inputCon {
  height: 90px;
  background-color: white;
  width: 100%;
  border-top: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.inputChat {
  padding: 20px;
  width: 85%;
  border: 0;

  &:focus {
    border: 0;
    outline: none;
  }
}

.icon {
  color: #169fed;
  border: 1px solid #e1e8ed;
  height: 25px;
  width: 25px;
  border-radius: 15px;
  padding: 10px;
}
.messagesContainer {
  padding: 15px 130px;
  display: flex;
  flex-direction: column-reverse;
  overflow: scroll;
}
.chatsCon {
  display: flex;
  height: 50px;
  margin-top: 13px;
}
.innerChat {
  display: flex;
  align-items: center;
  height: 90px;
}
.chatName {
  font-size: 16px;
  font-weight: bold;
}
.chatDate {
  font-size: 14px;
  font-weight: lighter;
  color: #808080;
  margin-left: 20px;
}
.chatContent {
  font-size: 14px;
  font-weight: lighter;
  color: #808080;
}

@media (max-width: 768px) {
  .mainContainer {
    width: 30%;
    border-radius: 10px;
    background-color: white;
    height: 700px;
  }
  .detailContainer {
    width: 65%;
    border-radius: 10px;
    background-color: white;
    height: 700px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: transparent;
  }
  .mainTop {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0 0;
    height: 50px;
  }
  .messageContent {
    height: 20px;
  }
  .topText {
    text-align: center;
    font-weight: lighter;
    font-size: 8px;
    width: 50%;
    height: 20px;
  }
  .topTextPicked {
    border-bottom: 2px solid black;
    text-align: center;
    font-weight: bolder;
    font-size: 8px;
    padding-bottom: 7px;
    width: 50%;
    height: 20px;
  }
  .mapItemContainer {
    border-bottom: 1px solid black;
    padding: 0 15px;
    margin-top: 10px;
    height: 70px;
  }
  .image {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 3px;
    background-color: #b4b6b7df;
  }
  .inner {
    display: flex;
    flex-direction: row;
    height: 25px;
  }
  .mappedContent {
    overflow: scroll;
    height: 640px;
  }
  .name {
    font-size: 8px;
    font-weight: bold;
  }
  .content {
    font-size: 7px;
    font-weight: lighter;
    color: #808080;
  }
  .date {
    text-align: right;
    font-size: 5px;
    font-weight: lighter;
    margin-top: 5px;
    margin: 10px 0;
  }
  .dateNote {
    text-align: center;
    font-size: 8px;
    font-weight: lighter;
    margin: 3px;
    color: #808080;
  }
  .header {
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 15px;
    height: 30px;
  }
  .notContent {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 5px;
    height: 640px;
    overflow: scroll;
    padding: 0 13px;
  }
  .imageNot {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border: 5px solid #e1e8ed;
    border-radius: 15px;
    margin-top: 10px;
  }
  .fullContent {
    width: 600px;
    margin-top: 40px;
    font-size: 14px;
    font-weight: lighter;
    word-spacing: 1px;
    letter-spacing: 1px;
  }
  .messagesContainer {
    height: 565px;
  }
  .noData {
    height: 25px;
  }
  .inputCon {
    height: 90px;
    background-color: white;
    width: 100%;
    border-top: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .inputChat {
    padding: 20px;
    width: 85%;
    border: 0;

    &:focus {
      border: 0;
      outline: none;
    }
  }

  .icon {
    color: #169fed;
    border: 1px solid #e1e8ed;
    height: 5px;
    width: 5px;
    border-radius: 15px;
    padding: 2px;
  }
  .messagesContainer {
    padding: 15px 20px;
    display: flex;
    flex-direction: column-reverse;
    overflow: scroll;
  }
  .chatsCon {
    display: flex;
    height: 50px;
    margin-top: 5px;
  }
  .innerChat {
    display: flex;
    align-items: center;
    height: 80px;
  }
  .chatName {
    font-size: 8px;
    font-weight: bold;
  }
  .chatDate {
    font-size: 7px;
    font-weight: lighter;
    color: #808080;
    margin-left: 8px;
  }
  .chatContent {
    font-size: 8px;
    font-weight: lighter;
    color: #808080;
  }
}
