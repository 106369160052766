.tableBody{
    width: 100%;
    border-radius: 15px;
    height: 100%;
    padding: 15px;
    margin: 20px 0px;

    .tables{
        width:100%;
        border-collapse:collapse;
        
    }

    .headRow{
        padding: 10px ;
        border-bottom: 1px solid gray;
        font-size:14px;
        
        .headbox{
            display: flex;
            align-items: center;
            justify-content: center;
        }
      
    }

    .tablebody{
        text-align: center;
        padding: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.227);
        font-size: 14px;

      
    }

    .green{
        color:green ;
    }
    
    .yellow{
        color:rgba(255, 123, 0, 0.974);
    }

    .red{
        color:red;
    }

      .mobileTable{
        display:none;
    }
}



@media (max-width:768px) {
    .tableBody{
        margin: 0px;
        padding:0px;
    .tables{
        display: none;
    }

    .mobileTable{
        display: block;


        .boxTab{
            display: grid;
            grid-template-columns: 1fr 50%;
            border-bottom:1px solid black ;
            padding: 5px;
            gap: 1rem;

        }

        .box :nth-child(2){
              font-weight: bold;
                color: black;
                font-size: 1 rem;
        }

        .box2{
            text-align: right;

            :nth-child(2){
                font-weight: bold;
                color: black;
            }
        }
    }

  
}
}