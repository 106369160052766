
.hbody *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
}

.hbody {
    width: 64%;
    display: flex ;
    flex-direction: column;
    gap:3rem;

    .bdxt{
    background-color: white;
    padding-bottom: 20px;
    min-width: 100%;
    padding: 30px 50px;
    border-radius: 10px;
    }

    .payHistory{
        width: 100%;
        font-weight: bolder;
        font-size: 1.2rem;
    }

    .analytics{
        display: flex;
        flex-direction: column;
        gap:3rem;
        width: 100%;
        margin: 1.5rem 0px;
    }
    .total{
        border: 1px solid #E1E8ED;
        border-radius: 10px ;
        width: 100%;
        padding: 1.5rem 3rem;

        h1{
            text-align: left;
        }
    }

    .boxss{
        display: flex;
        width: 100%;
        gap:3rem;
        
        
        .taw{
            width: 100%;
            border: 1px solid #E1E8ED;
            padding: 1.5rem 3rem;
            border-radius: 10px;
        }

    }

    .tablesection{
        background-color: white;
        padding:40px  40px;
        border-radius: 10px;
        height: 100%;

        .trHead{
            font-size: 18px;
            color: black;
            font-weight: bold;
        }

        .searchInput{
            padding: 10px;
            border: 1px solid #E1E8ED;
            width: 35%;
            margin: 20px 0px;
            border-radius: 5px;
            font-size: 16px;
            outline: none;
            margin: 30px 0px;
        }

    }
}

@media (max-width:768px) {
    .hbody{
        width: 100%;
        padding: 0px;


    .boxss{
        flex-direction: column;
        
    }

    .bdxt{
        padding: 0px 0px;
    }

    .tablesection{
        padding: 0px;
       
        .searchInput{
            width: 80%;
        }
    }

    }

    
}