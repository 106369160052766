.passwordInput {
  border-radius: 0px;
  // padding: 40px;
  height: 70px;
  font-weight: 400;
  border: none;
  // background-color: goldenrod;
  border-bottom: 2px solid #2d2d2d;
  font-family: Poppins, "sans-serif";
  font-size: 20px;
  outline: none;
}
