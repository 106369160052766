.list-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.list-empty {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($color: #000000, $alpha: 0.4);
  border-radius: 12px;

  h3 {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
}
